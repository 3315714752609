import React from "react";
import "./GameButton.css";

const GameButton = ({ state, row, col, onButtonPress }) => {
  return (
    <div
      className={`game button ${state}`}
      onClick={() => onButtonPress(row, col)}
    ></div>
  );
};

export default GameButton;
