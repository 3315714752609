import React from "react";

import "./ContentList.css";
import ContentListItem from "./ContentListItem";

// This should probably be pushed up and passed as a prop

const ContentList = ({ content }) => {
  const renderedContent = content.map((item) => {
    return (
      <ContentListItem
        key={item.header}
        header={item.header}
        description={item.description}
        image={item.image}
        address={item.address}
      />
    );
  });

  return (
    <div className="ui container">
      <div className="ui divided items">{renderedContent}</div>
    </div>
  );
};

export default ContentList;
