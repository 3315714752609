import React from "react";
import Navbar from "./components/Navbar";
import { Switch, Route } from "react-router";

import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import "./App.css";
import Lightsout from "./components/lightsout/Lightsout";

// Info to pass to components
const SiteHeader = "ledlow.dev";
const links = [
  { label: "Home", path: "/" },
  { label: "About Me", path: "/about" },
];

// Main app
const App = () => {
  return (
    <div className="fill">
      <Navbar header={SiteHeader} links={links} />

      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/lightsout">
          <Lightsout />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>

      <Footer />
    </div>
  );
};

export default App;
