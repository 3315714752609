import React from "react";
import "./About.css";

// eventually, this information will come from a rest service
const About = () => {
  return (
    <div className="ui container top bottom space">
      <div class="ui segment">
        <h2 class="ui right floated header">About Me</h2>
        <div class="ui clearing divider"></div>
        <p>
          I grew up in a small-ish town in Middle Tennessee called Shelbyville
          that is about an hour south of Nashville. I then spent about 10 years
          in Knoxville, TN where I did my undergraduate studies and had my first
          post college job. This is also where I met my wife, Liz, when she was
          getting her Master's degree. We moved to Greenville, SC in 2013 when I
          accepted an engineering position with an automotive company. We are
          currently still located in the Greenville area, but will soon be
          moving to Chicago.
        </p>
        <h2 class="ui right floated header">Academics</h2>
        <div class="ui clearing divider"></div>
        <div className="content">
          <h4 className="ui horizontal divider header">
            <i className="icon laptop" />
            Master of Science - Computer Science
          </h4>
          <div className="description">
            Syracuse University<div style={{ float: "right" }}>GPA: 4.0</div>
          </div>

          <table className="ui table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Description</th>
                <th>Grade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Course">
                  CPS 500 Computer Organization and Operating System Design
                </td>
                <td data-label="Description">
                  Fundamentals of computer organization and operating systems
                  design. Computer organization topics: CPU & pipeline
                  architecture, data representation and memory hierarchies,
                  assembly language and instruction sets. Operating system
                  concepts: system calls, processes, threads, synchronization,
                  memory management, input-output, traps, and file systems.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">
                  CIS 623 Structured Programming and Formal Methods
                </td>
                <td data-label="Description">
                  Reasoning about programs: specification, design, and
                  realization of provably correct programs. Program and data
                  structures, binding, procedures, recursion.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">
                  CIS 651 Mobile Application Programming
                </td>
                <td data-label="Description">
                  Development of applications for different mobile devices.
                  Creating effective user interfaces, efficient use of
                  persisitent storage, network services, GPS, maps and sensors.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">
                  CIS 655 Advanced Computer Architecture
                </td>
                <td data-label="Description">
                  Computer-architecture characteristics and their effect on the
                  design and performance of programs. Price-performance
                  tradeoffs, instruction set design, memory hierarchies,
                  pipelining, storage systems, selected topics in parallel
                  architectures. Architecture of specified computers.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">
                  CIS 657 Principles of Operating Systems
                </td>
                <td data-label="Description">
                  Design and implementation of operating systems. Process and
                  memory management, resource scheduling, file system
                  management, I/O and kernel services and structuring. Includes
                  weekly lab using a Unix-like operating system.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">
                  CIS 554 Object Oriented Programming in C++
                </td>
                <td data-label="Description">
                  Survey of basic C constructs. Data abstraction, classes,
                  derived classes, types, structures and template. Access
                  control, information hiding, multiple inheritance. Formatting
                  stream I/O, libraries, interfaces, modular system
                  0rganization. Substantial programming assignments.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">
                  CSE 681 Software Modeling and Analysis
                </td>
                <td data-label="Description">
                  Project-based course covering software modeling, architecture,
                  design, and implementation using diagramming, analysis tools,
                  and common sense engineering methods to analyze performance of
                  concurrent, message-driven systems.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">CSE 682 Software Engineering</td>
                <td data-label="Description">
                  Requirements and specifications including tools such as
                  PSL/PSA, SREM, design techniques; Functional decomposition;
                  data flow; data structure, theoretical issues in testing,
                  testing strategies: path; domain; mutation and error specific,
                  cost and reliability models.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">CSE 643 Computer Security</td>
                <td data-label="Description">
                  Operating system security. Unix security. Trusted Computing
                  Base. Authentication. Access control. Security models.
                  Capability. Sandboxing. Software vulnerabilities. Worms.
                  Viruses. Secure engineering principles. Secure programming.
                  Auditing. Forensics.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">CSE 644 Internet Security</td>
                <td data-label="Description">
                  Internet architecture. Security and attacks on TCP/IP, DNS,
                  and BGP protocols. Internet protocol security. Firewall.
                  Intrusion detection. Network traceback. Web security.
                  Encryption. Public Key infrastructure. One-way harsh function.
                  Digital signature. Security protocols.
                </td>
                <td data-label="Grade">A</td>
              </tr>
              <tr>
                <td data-label="Course">
                  CIS 675 Design and Analysis of Algorithms
                </td>
                <td data-label="Description">
                  Asymptotic analysis and recurrences; classical numeric
                  algorithms; advanced data structures; graph algorithms;
                  divide-and-conquer, greedy choice, dynamic programming, and
                  other computational strategies; NP-completeness.
                </td>
                <td data-label="Grade">A</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="content">
          <h4 className="ui horizontal divider header">
            <i className="icon cogs" />
            Bachelor of Science - Mechanical Engineering
          </h4>
          <div className="description">
            University of Tennessee
            <div style={{ float: "right" }}>GPA: 3.0</div>
          </div>
          <p>
            ABET accredited engineering program that focused on topics such as
            thermal engineering, mechanical design and analysis, and computer
            aided engineering.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
