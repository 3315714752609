import React from "react";
import "./Masthead.css";

const Masthead = () => {
  return (
    <div className="ui inverted vertical masthead center aligned segment">
      <div className="ui text container">
        <h1 className="ui inverted header">Welcome</h1>
        <h3>A place for me to share my passions.</h3>
      </div>
    </div>
  );
};

export default Masthead;
