import React from "react";
import ContentList from "./ContentList";
import OctoCat from "../media/images/Octocat/Octocat.png";
import WikiCook from "../media/images/WikiCook/logo.png";
import Masthead from "./Masthead";
import ReactLogo from "../media/images/React/logo.png";
import AWSLogo from "../media/images/AWS/logo.png";
import NodeLogo from "../media/images/Node/logo2.png";
import ApacheLogo from "../media/images/Apache/Apache_PoweredBy.svg";
import LightsImage from "../media/images/lightsout/lightsout.svg";

const content = [
  {
    header: "My GitHub",
    description:
      "Here you will find a few repositories of projects I have worked on over the years. Most of my repos are currently private, but I will be adding/opening up more in the future.",
    image: OctoCat,
    address: "http://www.github.com/jbledlow",
  },
  {
    header: "Team Project: WikiCook",
    description:
      "This was a fun project that I worked on with my team in our Software Engineering Course. Have you ever been to a recipe website, only to be forced to scroll through a dissertation on why this is the best meatloaf ever? That's how we felt. So we built this website to wade through all of that and return only the pertinent information. Note: Due to time constraints, we had to simplify the logic. So, it currently only works with a couple websites. Use this URL to see an example of how it works: https://natashaskitchen.com/easy-calzone-recipe/",
    image: WikiCook,
    address: "http://wikicook.azurewebsites.net",
  },
  {
    header: "Play A Game",
    description:
      "When I was younger, I loved the game Lights Out. For those who are too young, or weren't kind of nerdy like me, it was a game with 25 buttons in a grid that switched on and off when pressed. The goal was to turn out all the lights. Here is a version that I made for this site.",
    image: LightsImage,
    address: "/lightsout",
  },
];

const Home = () => {
  return (
    <div>
      <Masthead />
      <h4 className="ui horizontal divider header">
        <i className="info circle icon"></i>
        About this site
      </h4>
      <div className="ui container">
        <p>
          I have created this site to serve as a portfolio of sorts and to share
          things that I find interesting. At this time, the site has limited
          functionality, but I will be working to bring more content in the very
          near future.
        </p>
        <br />
        <p>The tech stack that I am using for this site is:</p>
        <div className="ui list">
          <div className="item">
            <img className="ui mini image" src={ReactLogo} alt="" />
            <div className="content">
              <div className="header">React</div>
              <div className="description">
                A javascript library for building user interfaces.
              </div>
            </div>
          </div>
          <div className="item">
            <img className="ui mini image" src={AWSLogo} alt="" />
            <div className="content">
              <div className="header">AWS Lightsail</div>
              <div className="description">
                A lightweight website hosting option.
              </div>
            </div>
          </div>
          <div className="item">
            <img className="ui mini image" src={NodeLogo} alt="" />
            <div className="content">
              <div className="header">Node.js</div>
              <div className="description">
                A javascript runtime environment
              </div>
            </div>
          </div>
          <div className="item">
            <img className="ui mini image" src={ApacheLogo} alt="" />
            <div className="content">
              <div className="header">Apache</div>
              <div className="description">An open source web server.</div>
            </div>
          </div>
        </div>
      </div>
      <h4 className="ui horizontal divider header">
        <i className="linkify icon"></i>
        Links to More Content
      </h4>
      <ContentList content={content} />
    </div>
  );
};

export default Home;
