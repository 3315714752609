import React from "react";
import "./GameControls.css";

const GameControls = ({ onResetPush, onRestartPush, moves }) => {
  return (
    <div className="gamecontrols">
      <div className="ui button" onClick={onResetPush}>
        Reset Level
      </div>
      <div className="ui button" onClick={onRestartPush}>
        Restart Game
      </div>
      <div className="indicator">
        <span>{`Moves: ${moves}`}</span>
      </div>
    </div>
  );
};

export default GameControls;
