import React from "react";
import GameButton from "./GameButton";
import "./Gamegrid.css";

const Gamegrid = ({ level, onButtonPress }) => {
  const renderedGrid = level.map((row, rndx) => {
    return (
      <div className="row">
        {row.map((col, cndx) => {
          return (
            <div className="column">
              <GameButton
                state={`${col ? "on" : "off"}`}
                row={rndx}
                col={cndx}
                onButtonPress={onButtonPress}
              />
            </div>
          );
        })}
      </div>
    );
  });

  return <div className="gameboard">{renderedGrid}</div>;
};

export default Gamegrid;
