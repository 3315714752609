import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ header, links }) => {
  const renderedLinks = links.map((link) => {
    return (
      <Link to={link.path} className="item">
        {link.label}
      </Link>
    );
  });
  return (
    <div className="ui menu inverted noround">
      <div className="header item">{header}</div>
      {renderedLinks}
    </div>
  );
};

export default Navbar;
