import React, { useState, useEffect } from "react";
import Gamegrid from "./Gamegrid";
import GameControls from "./GameControls";
import "./Lightsout.css";

const Lightsout = () => {
  const [currentBoard, setCurrentBoard] = useState(levels[0]);
  const [moves, setMoves] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);

  const onButtonPress = (row, col) => {
    setMoves(moves + 1);
    var temp = currentBoard.map((arr) => {
      return arr.slice();
    });
    // flip this button
    temp[row][col] = temp[row][col] ? 0 : 1;
    // flip above button
    if (row > 0) {
      temp[row - 1][col] = temp[row - 1][col] ? 0 : 1;
    }
    // flip below button
    if (row < currentBoard.length - 1) {
      temp[row + 1][col] = temp[row + 1][col] ? 0 : 1;
    }
    // flip right button
    if (col < currentBoard.length - 1) {
      temp[row][col + 1] = temp[row][col + 1] ? 0 : 1;
    }
    // flip left button
    if (col > 0) {
      temp[row][col - 1] = temp[row][col - 1] ? 0 : 1;
    }
    setCurrentBoard(temp);
  };

  // reset board and moves
  const onResetPush = () => {
    setCurrentBoard(levels[currentLevel]);
    setMoves(0);
  };

  const onRestartPush = () => {
    setCurrentLevel(0);
    setMoves(0);
  };

  // effect to check if win condition met
  useEffect(() => {
    var sum = 0;
    currentBoard.forEach((element) => {
      element.forEach((innerEl) => {
        sum += innerEl;
      });
    });
    if (sum === 0) {
      window.alert("You cleared the board!");
      setCurrentLevel((c) => c + 1);
      setMoves(0);
    }
  }, [currentBoard]);

  // load next level when currentLevel changes
  useEffect(() => {
    if (currentLevel < levels.length) {
      setCurrentBoard(levels[currentLevel]);
    } else {
      window.alert("You Win!");
      setCurrentLevel(0);
    }
  }, [currentLevel]);

  // top spcae styling in App.css
  return (
    <div className="top space">
      <div className="game header">{`Level ${currentLevel + 1}`}</div>
      <div className="game description">
        Push buttons until all lights are out.
      </div>
      <div className="wrapper">
        <GameControls
          onResetPush={onResetPush}
          onRestartPush={onRestartPush}
          moves={moves}
        />
        <Gamegrid level={currentBoard} onButtonPress={onButtonPress} />
      </div>
      <div className="game description">
        Levels 1-5 can be solved in 6 Steps.
        <br />
        Levels 6-10 can be solved in 7 Steps.
        <br />
        Levels 11-15 can be solved in 8 Steps.
        <br />
        Levels 16-20 can be solved in 9 Steps.
        <br />
        Levels 21-25 can be solved in 10 Steps.
      </div>
    </div>
  );
};

const levels = [
  [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [1, 0, 1, 0, 1],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ],
  [
    [1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1],
    [0, 0, 0, 0, 0],
    [1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1],
  ],
  [
    [0, 1, 0, 1, 0],
    [1, 1, 0, 1, 1],
    [1, 1, 0, 1, 1],
    [1, 1, 0, 1, 1],
    [0, 1, 0, 1, 0],
  ],
  [
    [0, 0, 0, 0, 0],
    [1, 1, 0, 1, 1],
    [0, 0, 0, 0, 0],
    [1, 0, 0, 0, 1],
    [1, 1, 0, 1, 1],
  ],
  [
    [1, 1, 1, 1, 0],
    [1, 1, 1, 0, 1],
    [1, 1, 1, 0, 1],
    [0, 0, 0, 1, 1],
    [1, 1, 0, 1, 1],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1],
    [0, 1, 1, 1, 0],
  ],
  [
    [1, 1, 1, 1, 0],
    [1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1],
    [1, 1, 1, 1, 0],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 0, 1, 0, 0],
    [0, 1, 0, 1, 0],
    [1, 0, 1, 0, 1],
    [0, 1, 0, 1, 0],
  ],
  [
    [0, 1, 0, 1, 0],
    [1, 1, 1, 1, 1],
    [0, 1, 1, 1, 0],
    [0, 1, 0, 1, 1],
    [1, 1, 1, 0, 0],
  ],
  [
    [0, 1, 1, 1, 0],
    [0, 1, 1, 1, 0],
    [0, 1, 1, 1, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ],
  [
    [1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1],
    [0, 1, 1, 1, 0],
  ],
  [
    [1, 1, 1, 1, 1],
    [0, 1, 0, 1, 0],
    [1, 1, 0, 1, 1],
    [0, 1, 1, 1, 0],
    [0, 1, 0, 1, 0],
  ],
  [
    [0, 0, 0, 1, 0],
    [0, 0, 1, 0, 1],
    [0, 1, 0, 1, 0],
    [1, 0, 1, 0, 0],
    [0, 1, 0, 0, 0],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 1, 0, 0, 0],
    [0, 1, 0, 0, 0],
    [0, 1, 0, 0, 0],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 1, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 1, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ],
  [
    [1, 0, 0, 0, 0],
    [1, 0, 0, 0, 0],
    [1, 0, 0, 0, 0],
    [1, 0, 0, 0, 0],
    [1, 1, 1, 1, 1],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 1, 0, 0],
    [0, 1, 1, 1, 0],
    [1, 1, 1, 1, 1],
  ],
  [
    [0, 0, 1, 0, 0],
    [0, 1, 0, 1, 0],
    [1, 0, 1, 0, 1],
    [0, 1, 0, 1, 0],
    [0, 0, 1, 0, 0],
  ],
  [
    [1, 0, 1, 0, 1],
    [0, 0, 0, 0, 0],
    [1, 0, 1, 0, 1],
    [0, 0, 0, 0, 0],
    [1, 0, 1, 0, 1],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [1, 0, 0, 0, 1],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ],
  [
    [0, 1, 1, 1, 1],
    [0, 1, 0, 0, 0],
    [0, 1, 1, 1, 0],
    [0, 1, 0, 0, 0],
    [0, 1, 0, 0, 0],
  ],
  [
    [0, 1, 1, 1, 0],
    [1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1],
    [0, 1, 1, 1, 0],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 1, 1, 1],
    [0, 0, 1, 1, 0],
    [0, 0, 1, 0, 0],
  ],
  [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [1, 0, 0, 0, 1],
    [1, 1, 1, 1, 1],
    [0, 1, 0, 0, 1],
  ],
  [
    [1, 0, 0, 0, 0],
    [1, 1, 0, 0, 0],
    [1, 1, 1, 0, 0],
    [1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1],
  ],
];

export default Lightsout;
