import React from "react";

const ContentListItem = ({ header, description, image, address }) => {
  return (
    <div key={header} className="item ui segment">
      <div className="ui small image">
        <img src={image} alt={description} />
      </div>
      <div className="middle aligned content">
        <div className="header">{header}</div>
        <div className="description">
          <p>{description}</p>
        </div>
        <div className="extra">
          <div
            onClick={() => {
              if (
                address.indexOf("http://") === 0 ||
                address.indexOf("https://") === 0
              ) {
                window.open(address);
              } else {
                window.open(address, "_self");
              }
            }}
            className="ui right floated button"
          >
            Go
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentListItem;
