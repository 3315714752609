import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="ui inverted vertical footer segment">
      <div className="ui container">&copy; 2021 Jonathan Ledlow</div>
    </div>
  );
};

export default Footer;
